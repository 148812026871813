<template>
    <a-modal type="primary" visible title="Inspection Request" :onCancel="handleCancel" okText="Send Mail" @ok="onSubmit"
        width="50%">
        <div class="project-modal">
            <FormValidationWrap>
                <VerticalFormStyleWrap>
                    <a-form name="sDash_validation-form" ref="formRef" @finish="onSubmit" :model="formState" :rules="rules"
                        :layout="formState.layout">
                        <a-row :gutter="25">
                            <a-col :md="24" :xs="24">
                                <a-form-item ref="subject" label="Subject" name="subject">
                                    <a-input v-model:value="formState.subject" placeholder="Subject" size="large" />
                                </a-form-item>
                            </a-col>
                            <a-col :md="12" :xs="12"><a-form-item label="Inspection Companies" name="partnersIds">
                                    <a-select v-if="partnerApi.length" mode="multiple" v-model:value="formState.partnersIds"
                                        placeholder="Please choose Inspection Company" show-search style="width: 100%"
                                        :filter-option="customFilter">
                                        <a-select-option v-for="(partner, index) in partnerApi" :key="index"
                                            :value="partner.id">
                                            {{ partner.name }} ({{ partner.countryName }})</a-select-option>
                                    </a-select>
                                    <a-select v-else placeholder="Please choose Owner">
                                    </a-select>
                                </a-form-item>
                            </a-col>
                            <a-col :md="12" :xs="12"><a-form-item label="Inspection SOW" name="stateOfWorksIds">
                                    <a-select v-if="InspectionSOWApi.length" mode="multiple"
                                        v-model:value="formState.stateOfWorksIds" placeholder="Please choose state of work"
                                        show-search style="width: 100%" @change="addSowToMessage"
                                        :filter-option="customFilter">
                                        <a-select-option v-for="(sow, index) in InspectionSOWApi" :key="index"
                                            :value="sow.id">
                                            {{ sow.name }}</a-select-option>
                                    </a-select>
                                    <a-select v-else placeholder="Please choose state of work">
                                    </a-select>
                                </a-form-item>
                            </a-col>
                            <a-col :xs="24" :md="24" v-if="SingleProductApi.files && SingleProductApi.files.length">
                                <a-form-item label="Attached files" name="filesIds">
                                    <a-checkbox-group v-model:value="formState.filesIds" style="width: 100%">
                                        <a-row>
                                            <a-col v-for="file in SingleProductApi.files" :key="file.id" :span="24">
                                                <a-checkbox :value="file.id"> <span class="file-text"
                                                        :title="file.fileName">{{ getFileLabel(file.fileType) }}</span>
                                                </a-checkbox>
                                                <a @click="downloadFile(file)">
                                                    <sdFeatherIcons type="download" />
                                                </a>
                                            </a-col>
                                        </a-row>
                                    </a-checkbox-group>
                                </a-form-item>
                            </a-col>
                            <a-col :xs="24" :md="24">
                                <div class="sDash_editor">
                                    <ckeditor :editor="editor" v-model="editorData" :config="editorConfig"></ckeditor>
                                </div>
                            </a-col>

                        </a-row>
                    </a-form>
                </VerticalFormStyleWrap>
            </FormValidationWrap>
        </div>
    </a-modal>
</template>

<script setup>
import { ref, onMounted, computed, defineProps, reactive, defineEmits, watch } from 'vue';
import { notification } from "ant-design-vue";
import { useFileDownload } from '@/composable/useFileDownload';
import { DataService } from "@/config/dataService/dataService";
import { useStore } from "vuex";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
    FormValidationWrap,
    VerticalFormStyleWrap,
} from "../../forms/overview/Style";

const { downloadFile } = useFileDownload();
const { state, dispatch } = useStore();
const props = defineProps({
    product: String,
});
const emit = defineEmits(['Cancel'])

const SingleProductApi = computed(() => state.SingleProductApi.data);
const partnerApi = computed(() => state.partnerApi.data);
const InspectionSOWApi = computed(() => state.InspectionSOWApi.data);

const formRef = ref();
const rules = {
    stateOfWorksIds: [
        {
            type: "array",
            required: true,
            message: "Select SOWs",
            trigger: "change",
        },
    ],
    partnersIds: [
        {
            type: "array",
            required: true,
            message: "Select partners",
            trigger: "change",
        },
    ],
    message: [
        {
            required: true,
            message: "Message is required",
            trigger: "change",
        },
    ],
    subject: [
        {
            required: true,
            message: "Subject is required",
            trigger: "change",
        },
    ],
};
const formState = reactive({
    layout: "vertical",
    productId: props.product.id,
    message: "",
    subject: `Insprction Request(${props.product.dealNumber})`,
    filesIds: [],
    partnersIds: [],
    stateOfWorksIds: [],
});

const productDiscription = computed(() => props.product.sizeId
    ? `${props.product.categoryName} ${props.product.sizeName}, ${props.product.weightName}, ${props.product.gradeName}, ${props.product.threadName}, ${props.product.rangeName}`
    : props.product.note);
const quantity = computed(() => SingleProductApi.value.weightsConversion ? `${SingleProductApi.value.weightsConversion.meter} meters, ${SingleProductApi.value.weightsConversion.joint} joint, ${SingleProductApi.value.weightsConversion.foot} ft, ${SingleProductApi.value.weightsConversion.tonne} Tonnes` : '');
const Location = computed(() => {
    const components = [];
    if (props.product.countryName)
        components.push(props.product.countryName);

    if (props.product.stockLocationMaps)
        components.push(props.product.stockLocationMaps);

    if (props.product.stockLocation)
        components.push(props.product.stockLocation);

    return components.join(', ');
});

const sowText = computed(() => {
    const selectedSow = InspectionSOWApi.value.filter(item => formState.stateOfWorksIds.includes(item.id));
    return selectedSow.map(item => `${item.name}`).join(', ');
});


const editor = ref(ClassicEditor);
const editorData = ref(`<h3>Good Day,</h3>

    <p>Please provide your best offer, including price and duration, for the inspection as follows:</p>

    <ul>
        <li><strong>Product:</strong> ${productDiscription.value}</li>
        <li><strong>Quantity:</strong> ${quantity.value} </li>
        <li><strong>Location:</strong> ${Location.value}</li>
    </ul>

    <p><strong>Scope of Work:</strong> <span></span></p>

    <p>Best regards</p>`);
const editorConfig = ref({
    toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
    extraAllowedContent: 'span',
});
const addSowToMessage = () => {
    // Get the value of sowText
    const sowValue = sowText.value;
    editorData.value = editorData.value.replace(/<strong>Scope of Work:<\/strong>(.*?)<\/p>/gs, `<strong>Scope of Work:</strong> ${sowValue}</p>`);
};
const addQuantity = () => {
    editorData.value = editorData.value.replace(/<strong>Quantity:<\/strong>(.*?)<\/li>/gs, `<strong>Quantity:</strong> ${quantity.value}</li>`);
};


onMounted(async () => {
    dispatch("inspectionSOWInfo");
    dispatch("partnerWithInspectionActivityByCountry");
    dispatch("getProduct", props.product.id);
});



const customFilter = (input, option) => {
    return option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};
const getFileLabel = (fileType) => {
    switch (fileType) {
        case 1:
            return 'Picture Product'
        case 2:
        case 3:
            return 'Certificate & MTR'
        case 4:
            return 'Inspection Report'
        case 5:
            return 'Supplier Proposal'
    }
};


const onSubmit = async () => {
    formRef.value
        .validate()
        .then(async () => {
            try {
                formState.message = editorData.value;
                var response;
                response = await DataService.post("api/InspectionRequest", formState);

                if (response) {
                    if (response.succeeded) {
                        notification.success({
                            message: "Inspection requests sent successfully",
                        });
                        emit('cancel')
                    }
                } else {
                    notification.error({
                        message: response.message,
                    });
                }
            } catch (err) {
                notification.error({
                    message: "Error while sending mail",
                });
            }
        })
}

const handleCancel = () => {
    emit('cancel')
};

watch(
    () => quantity.value,
    () => {
        addQuantity();
    }
);
</script>
